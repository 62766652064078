body {
  background-color: #f4f5f7 !important;
}
.layout {
  width: 100% !important;
  margin-left: 0;
}
.contentdiv {
  padding: 0rem 0rem 0rem 0rem !important;
  /* background-color: #f0f0ec !important; */
}
.fw-bold {
  font-weight: 600 !important;
}
.billcrd {
  padding: 0px;
  background: #fff;
  height: 100vh;
  padding-left: 0px !important;
}
.time {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #1f1f1f;
  margin-bottom: 2px;
}

.address {
  padding: 0px 0px 10px 15px;
}
.billtab > .active > span {
  background-color: #ffcb44;
  color: #232019;
  font-size: 22px;
  font-weight: 600;
}
.billtab > * {
  padding-left: 0px;
  text-align: center;
  cursor: pointer;
}
.tablediv {
  height: calc(100vh - 450px);
  overflow-y: auto;
  padding: 0px 20px 15px 8px;
}
.itemdiv {
  font-size: 14px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-right: 5px;
}
.itemnm {
  margin-bottom: -7px;
  font-size: 13px;
  font-weight: 600;
}
.itemdiv .small {
  font-size: 8px;
}
.divright {
  text-align: right;
}
.morebtn {
  margin-bottom: 0px;
  display: flex;
  padding-left: 0px;
  align-items: center;
  text-align: center;
  background: #e8e7e6;
}
.morebtn .btntab {
  display: block;
  align-items: center;
  column-gap: 0.25em;
  font-size: 18px;
  color: #fff; /*#939392*/
  padding: 12px 0px;
  cursor: pointer;
  border-right: 1px solid #ffffff78;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
  background: #006495;
}
.totalbtn .form-check-input {
  margin-top: 0px !important;
}
.morebtn .fa {
  margin-bottom: 0px;
}
.morebtn .active {
  color: #000 !important; /*#fff*/
  background: #ffcb44 !important; /*#999*/
}
.leftdiv {
  width: 67% !important;
}
.rightdiv {
  width: 32% !important;
}
.closeicon { 
  font-size: 18px;
  color: red;
}
.footerbtn {
  position: fixed;
  width: 34%;
  bottom: 0px;
  padding-right: 10px;
  background-color: #fff;
}
.totaldiv {
  font-size: 15px;
  padding: 5px 18px 0px 8px;
  margin-left: 0px;
}

.totalwrd {
  font-size: 15px;
}
.savebtn {
  text-transform: capitalize;
  background: #ffcb44 !important;
  color: #232019 !important;
  border-radius: 10px;
  border: 1px solid #ffcb44 !important;
  width: 100%;
  font-weight: 600;
  font-size: 26px !important;
  padding: 14px 12px !important;
}
.totalbtn {
  padding: 8px 18px 10px 8px;
}

.sidemnu {
  padding: 15px 0px 0px 30px;
}
.menunm .grid-margin {
  margin-bottom: 1rem !important;
}
.menunm .card-body {
  padding: 8px 15px !important;
  color: #232019;
  border-radius: 10px;
  font-size: 18px;
  background: #fff;
}
.menunm .itemno {
  color: #555454;
  font-size: 16px;
  margin-top: 4px;
}
.sidemnuovr {
  padding-right: 10px;
  overflow-y: auto;
  height: 85vh;
}
.currentcrd {
  background: #ffcb44 !important;
}

.filter_scrl .row > * {
  padding-right: 5px;
}

.crdscrl {
  height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.filter_scrl {
  padding-right: 20px;
  overflow-y: auto;
  height: 85vh;
  overflow-x: hidden;
}
.filtercrd .card-body {
  padding: 10px 10px !important;
  background: #fff;
  border-radius: 10px;
}
.label-blue {
  background: #414ed1;
  color: #fff;
  text-transform: capitalize;
  border-radius: 10px;
  font-size: 9px !important;
  font-weight: 600;
  margin-top: 5px;
  padding: 3px 7px;
}

.filtercrd .name {
  color: #232019;
  font-size: 15px;
  font-weight: 600;
  word-break: break-word;
}

.filtercrd .price {
  font-size: 16px;
  color: #28a745;
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 0px;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 5;
}
::-webkit-scrollbar-thumb {
  background: #a4a4a4;
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover {
  background: #a4a4a4;
}

.text-right {
  text-align: right;
}
.navicon {
  cursor: pointer !important;
}
.navmenu {
  visibility: initial !important;
}

.paydiv {
  padding: 10px;
  color: #fff;
  text-align: center;
  background: #555454;
}

.alertdiv {
  padding: 0px 15px 0px 30px;
}
.alert {
  position: relative;
  padding: 13px 10px !important;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.valign {
  vertical-align: middle !important;
  margin: auto 0px;
}

.headernav {
  padding: 30px 30px 0px 30px;
}
.newordbtn {
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  color: #232019;
  text-transform: capitalize;
  margin-top: -4px;
  letter-spacing: 1px;
  background: #ffcb44;
  line-height: 32px;
  padding: 0px 17px;
}
.kotbtn {
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  text-transform: capitalize;
  margin-top: -4px;
  letter-spacing: 1px;
  line-height: 32px;
  padding: 0px 17px;
}
.searchcss {
  margin-right: 15px;
}

.searchcss .form-control {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  color: #000;
  border: 0px solid #ebeaea;
  border-radius: 10px;
  margin-top: -6px;
}
.searchicon {
  border: 0px solid #ebeaea !important;
  background-color: #fff !important;
  font-size: 16px !important;
  color: #938f8f !important;
  margin-top: -6px;
  padding: 11px 15px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.billtab {
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  list-style: none;
  margin-top: 0px;
}

.billtab > * > span:hover {
  background-color: #ffcb44;
  color: #232019;
}
.billtab > * > :first-child {
  display: block;
  align-items: center;
  column-gap: 0.25em;
  background: #555454;
  font-size: 16px;
  color: #fff;
  padding: 10px 15px;
  text-transform: uppercase;
  border: 1px solid #ffffff78;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}
/* .ad {
  background: #28a745;
  padding: 10px 14px;
  color: #fff;
  width: 45px;
  font-size: 17px;
  border-radius: 10px;
} */
.ad {
  background: #28a745;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  padding: 3px 2px;
  width: 36px;
  text-align: center;
  margin-right: 10px;
}
/* .ad {
  background: #28a745;
  border-radius: 10px;
  color: #fff;
  font-size: 17px;
  padding: 10px 14px;
  width: 45px;
  text-align: center;
  margin-right: 10px;
} */
a {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
}

.mobilecss .uk-offcanvas-bar {
  width: 320px;
  padding: 20px 20px;
  background: #e8e7e6;
}
.mobilecss .uk-offcanvas-bar .uk-close {
  color: #555454 !important;
}
.uk-offcanvas-bar .uk-nav-default > li > a,
.uk-offcanvas-bar .uk-nav-default > li > a:hover {
  color: #555454;
  font-weight: 400;
  font-size: 16px;
}
.mobilecss .uk-offcanvas-bar .listcss {
  padding: 4px 0px;
  margin-top: 0px;
}
/* .navli li {
  position: relative;
  display: block;
  padding: 0.4rem 1rem;
  font-size: 16px;
  color: #3a3939;
  text-decoration: none;
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
} */

/* .navli li {
  background-color: #fff;
  background: #f4f5f7;
  border: 0 solid rgba(0, 0, 0, 0.125);
  color: #3a3939;
  display: block;
  font-size: 0.812rem;
  padding: 0.4rem 1rem;
  position: relative;
  text-decoration: none;
} */
/* .navli li {
  background: #f4f5f7;
  border: 0 solid rgba(0, 0, 0, 0.125);
  color: #3a3939;
  padding: 5px 20px 0px 20px;
  display: block;
  font-size: 0.812rem;
  position: relative;
  text-decoration: none;
} */
.navli li {
  background: #f4f5f7;
  border: 0 solid rgba(0, 0, 0, 0.125);
  color: #3a3939;
  display: block;
  font-size: 0.812rem;
  padding: 0px 20px 0;
  position: relative;
  text-decoration: none;
}
.navmenu {
  width: 300px !important;
}

.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .navbar-date-picker
  .form-control {
  max-width: 155px;
  height: 34px;
  border-radius: 6px;
}
.navclck {
  cursor: pointer !important;
}

.navmenu .offcanvas-header {
  padding: 14px 30px;
  font-size: 16px;
}

.navmenu .offcanvas-body {
  padding: 0px 10px;
}
.ctlbtn,
.ctlbtn:hover,
.ctlbtn:focus {
  padding: 8px 8px;
  color: #555454;
  font-size: 12px;
  border-radius: 0px;
  border: 1px solid #a5a5a5;
}
.ctlbtn i {
  font-size: 12px;
}

.footerbtn .discnt {
  width: 100px;
}

.nveg {
  width: 20px;
  margin-right: 7px;
}

.footerbtn .discnt {
  width: 100px;
}
.tshwdiv {
  height: 70vh;
  overflow-y: auto;
  padding: 0px 25px 15px 15px;
}

.tshwdiv .tcnt {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  padding-top: 10px;
}
.tshwdiv .cstnm {
  font-size: 13px;
  font-weight: 600;
}
/* .groundtxt {
  font-size: 17px !important;
  padding: 11px 10px !important;
  color: #28a745 !important;
  font-weight: 700 !important;
} */

.groundtxt {
  color: #28a745 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 9px 10px !important;
}

.savetab {
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  list-style: none;
  margin-top: 0px;
}
.savetab > * > :first-child {
  display: block;
  align-items: center;
  column-gap: 0.25em;
  background: #056296; /*#999*/
  font-size: 12px;
  font-weight: 600;
  color: #fff; /*#000*/
  padding: 12px;
  text-transform: uppercase;
  border: 1px solid #ffffff78;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

.savetab > * {
  padding-left: 0px;
  text-align: center;
  cursor: pointer;
}
.themeclr {
  background: #25a945 !important; /*#ffcb44*/
}

.topicon {
  font-size: 20px;
  padding-top: 5px;
}
.topicon span {
  cursor: pointer;
}

.tablenames {
  padding: 20px 15px;
  background: #fff;
  border-radius: 10px;
}
.tablenames .card-body {
  padding: 15px 15px !important;
  background: #f0f0ec;
  border-radius: 10px;
}
.tablenames .active {
  background: #febc1a !important;
}
.tablenames .grid-margin {
  margin-bottom: 0.7rem;
}
.tablenames .active1 {
  background: #6ed398 !important; /*#26d770*/
}
.tablenames .active2 {
  background: #e8485a !important;
}
.tablenames .active3 {
  background: #b1e4ba !important;
}
.tablenames .active4 {
  background: #a5a6a4 !important;
}
.tablenames .active5 {
  background: #fac744 !important;
}
.tablenames .dinein {
  /* background: #c61717 !important; */
  background: #921918 !important;
}
.tablenames .delivery {
  /* background: #ef8340 !important; */
  background: #e97123 !important;
}
.tablenames .counter {
  /* background: #ffde59 !important; */
  background: #ffcb44 !important;
}
.tablenames .price {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 3px;
  font-weight: 600;
}
.dottedhr {
  border: 1px dotted #eee;
  margin-bottom: 20px;
  margin-top: 10px;
}
.modal .modal-dialog .tableview .modal-body {
  padding: 20px 20px;
}
.modal-dialog .ctlbtn,
.modal-dialog .ctlbtn:focus,
.modal-dialog .ctlbtn:hover {
  border: 1px solid #a5a5a5;
  border-radius: 0;
  color: #555454;
  font-size: 12px;
  padding: 14px;
}
.customchk .form-check-label {
  min-height: 18px;
  display: flex;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}
.customchk .form-check-inline {
  padding-left: 0px;
  margin-right: 0.5rem;
}

.customchk .checkcss {
  width: 18px;
  display: block;
  height: 18px;
  /* margin-top: 0.25em; */
  margin-right: 10px;
  /* vertical-align: top; */
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  /* color-adjust: exact; */
  border-radius: 0.25em;
}
/* .customchk .checkbox1 {
  background-color: #f0f0ec;
  border-color: rgba(0, 0, 0, 0.25);
} */
.customchk .checkbox1 {
  background-color: #93949e;
  border-color: #93949e;
}
/* .customchk .checkbox2 {
  background-color: mediumseagreen;
  border-color: mediumseagreen;
} */
.customchk .checkbox2 {
  background-color: #6ed398;
  border-color: #6ed398;
}
/* .customchk .checkbox3 {
  background-color: coral;
  border-color: coral;
} */

.customchk .checkbox3 {
  background-color: #28a749;
  border-color: #28a749;
}
/* .customchk .checkbox4 {
  background-color: #ffcb44;
  border-color: #ffcb44;
} */
.customchk .checkbox4 {
  background-color: #febc1a;
  border-color: #febc1a;
}
/* .customchk .checkbox5 {
  background-color: burlywood;
  border-color: burlywood;
} */

.customchk .checkbox5 {
  background-color: #e8485a;
  border-color: #e8485a;
}
.customchk .checkbox6 {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
}
.customchk .checkbox7 {
  background-color: #ffcb44;
  border-color: #ffcb44;
}
.formcss {
  padding: 10px;
}

.formcss .form-control {
  border: 1px solid #dee2e6;
  font-weight: 400;
  font-size: 0.875rem;
  border-radius: 4px;
  height: 2.3rem;
}
.formcss .btn {
  padding: 8px 12px;
}
.formcss .btn i {
  font-size: 20px;
}
.forms-sample .btn {
  height: 40px;
}
.navbar-expand-sm .offcanvas .offcanvas-header {
  display: none;
}
.tshwdiv .form-group {
  margin-bottom: 0.8rem;
}
.navbar-expand-sm .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.mr10 {
  margin-right: 10px;
}

.disselect .btn-outline-secondary {
  border: 1px solid #dee2e6 !important;
  color: #555454 !important;
  padding: 10px;
}
.disselect .dropdown-toggle::after {
  display: inline-block;
  margin-left: 17px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.select.form-control {
  border: 1px solid #dee2e6;
}
.tblalign {
  text-align: -webkit-right;
}
.actnm {
  font-size: 10px;
  color: #000;
}
.actnm1 {
  font-size: 10px;
  color: #fff;
}
.tblicon {
  padding: 5px 7px !important;
  right: 15px;
  top: 18px;
  /* background-color: #000 !important;
  background-image: none !important;
  border-color: #000 !important; */
  position: absolute;
  color: #000 !important;
  /* margin-top: -16px!important; */
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.show:not(.hiding),
  .offcanvas-sm.showing {
    transform: none;
  }

  .offcanvas-sm.hiding,
  .offcanvas-sm.show,
  .offcanvas-sm.showing {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.show:not(.hiding),
  .offcanvas-md.showing {
    transform: none;
  }

  .offcanvas-md.hiding,
  .offcanvas-md.show,
  .offcanvas-md.showing {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.show:not(.hiding),
  .offcanvas-lg.showing {
    transform: none;
  }

  .offcanvas-lg.hiding,
  .offcanvas-lg.show,
  .offcanvas-lg.showing {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.show:not(.hiding),
  .offcanvas-xl.showing {
    transform: none;
  }

  .offcanvas-xl.hiding,
  .offcanvas-xl.show,
  .offcanvas-xl.showing {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.show:not(.hiding),
  .offcanvas-xxl.showing {
    transform: none;
  }

  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show,
  .offcanvas-xxl.showing {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

/* ================================================================================================= */
.kotslr .row > * {
  padding-right: 15px;
}
.kotcrd {
  padding: 10px!important;
   
  
}
.kotcrd .badge-opacity-danger {
  background-color: #f8d7da;
  color: #f45a68;
}
.kotcrd hr {
  margin-bottom: 8px;
  margin-top: 5px;
  border: 1px solid #d3d3d3;
}
.kotcrd .finishbtn {
  width: 95%;
  font-size: 11px;
  text-transform: capitalize;
  text-align: left;
  padding: 0.875rem 0.8rem;
}
.kotcrd .printbtn {
  padding: 11px 14px;
 }

.kotitemsbtn {
  padding: 0px 2px!important;
}
.kotallitemsreadybtn {
  padding: 6px 11px;
}
.kotcrd .badge {
  font-size: 10px;
  padding: 4px 10px;
  color: #000;
}
.kotcrd p {
  font-size: 10px;
  font-weight: 600;
}
.tablenames .bg-white {
  color: #000;
  font-weight: 800;
}
.tablenames .inprocess {
  background: #ffcb44 !important;
}
.kotcrd .btn-warning {
  background: #ffcb44 !important;
  border: 1px solid #ffcb44;
  color: #fff;
}
.tablenames .inque {
  background: #d3d3d3 !important;
}
.kotcrd .btn-inque {
  background: #d3d3d3 !important;
  border: 1px solid #d3d3d3;
  color: #000;
}
.tablenames .ready {
  background: #28a749 !important;
}
.kotcrd .btn-ready {
  background: #28a749 !important;
  border: 1px solid #28a749;
  color: #fff;
}
.tablenames .kottabs .active {
  background: none !important;
}

.home-tab .kotcrd .printbtn {
  border: 1px solid #000;
}

.home-tab .kottabs .nav-tabs .nav-item .nav-link.active {
  background: transparent;
  color: #006494;
  font-weight: 700;
}
/* ******************************************************************************************************** */
.printcss .name {
  text-align: center;
  padding: 0px;
  margin: 0;
  font-size: 10px !important;
}
.printcss {
  /* margin-top: 10px !important; */
  margin: 0 auto;
  width: 100%;
  width: auto;
  background: #fff;
  font-size: 10px !important;
  font-family: "PT Sans", sans-serif;
  color: #000;
}
.logo {
  width: 50%;
  text-align: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0 5px;
  display: block;
  margin: 0 auto;
}
.gst {
  padding: 0px;
  margin: 0;
}
.tblecss {
  width: 100%;
  table-layout: fixed;
}
.printcss .txtrg {
  text-align: right !important;
}
.printcss .txtct {
  text-align: center !important;
}
.printcss .txtlf {
  text-align: left !important;
}
.printcss .thank {
  text-align: center;
  padding: 0px;
  margin: 0;
  font-size: 10px;
}
.tblecss2 .thcss {
  font-size: 10px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  vertical-align: middle;
  border-left: none;
  border-right: none;
  padding: 3px 0px;
  text-align: left;
}
.tblecss2 .itemth {
  width: 35%;
}
.tblecss2 .qtyth {
  width: 10%;
}
.tblecss2 .rateth {
  width: 25%;
}
.tblecss2 .amtth {
  width: 30%;
}
.tblecss2 .tdcss {
  font-size: 10px;
  text-align: left;
  vertical-align: bottom;
  padding: 3px 0px;
  word-break: keep-all;
}
.tblecss2 {
  width: 100%;
  table-layout: fixed;
}
.tblecss2 .subtl {
  font-size: 10px;
  text-align: right;
  vertical-align: bottom;
  padding: 0px;
}
.printcss .brtp {
  border-top: 1px solid black !important;
}
.printcss .brtpd {
  padding: 15px 0;
  border-top: 1px dashed black !important;
}
.tblecss2 .tlt {
  text-align: right;
  font-size: 10px;
  border-bottom: 1px dashed black !important;
  border-top: 1px dashed black !important;
  padding: 4px 0px;
  padding-right: 12px;
}
.pd0 {
  padding-right: 0px !important;
}
.info {
  font-size: 10px !important;
}
/* ================================================================================================= */

.offcanvas {
  background: #f4f5f7;
}

.itemsize {
  height: 110px !important;
}

.pricepos {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

[data-title] {
  /* outline: red dotted 1px; optional styling */
  /* font-size: 30px; optional styling */

  position: relative;
  cursor: help;
  z-index: 2;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}
[data-title]:hover::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
}

.posli i {
  font-size: 22px;
  vertical-align: middle;
}

/* *******************************************09-12-2023*************************************************** */

.dinebtn {
  background: #921918;
  /* background: #c61717; */
  padding: 10px 12px;
  color: #fff;
  font-weight: 600;
}
/* *******************************************11-12-2023*************************************************** */

.deliverybtn {
  background: #e97123;
  /* background: #ef8340; */
  padding: 10px 12px;
  color: #fff;
  font-weight: 600;
  border: 1px solid transparent;
}

.counterbtn {
  background: #ffcb44;
  /* background: #ffde59; */
  padding: 10px 12px;
  color: #10171f;
  font-weight: 600;
  border: 1px solid transparent;
}

/* ******************************************************************************************************** */

.vrttcrd .card-body 
{
    padding: 12px 15px !important;
    background: #f0f0ec;
    border-radius: 10px;
}
@media (min-width: 992px)
{
.vrttcrd .modal-lg, .vrttcrd .modal-xl 
{
    max-width: 60%;
}
}




@media only screen and (max-width: 1024px) and (min-width: 321px)  
{
  .leftdiv 
 {
    width: 50%!important;
  }
.rightdiv {
    width: 50%!important;
}
.customchk {
    margin-top: 20px;
}
.footerbtn {
    background-color: #fff;
    bottom: 0;
    padding-right: 0px;
    position: fixed;
    width: 50%;
}
.card {
    word-wrap: break-word;
    background-clip: initial;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    display: inline-block;
    flex-direction: column;
    min-width: 60%;
    margin-right: 20px;
    position: relative;
}
.sidemnuovr {
    overflow: auto;
    white-space: nowrap;height:auto!important;
    padding-bottom: 10px;
    margin-left: 35px;
    padding-left: 0px!important;
}
.address {
    padding: 0px 20px 10px 15px;
}
.filter_scrl {
  height: 80vh!important;
  margin-left: 35px;
  overflow: auto;
  padding-bottom: 10px;
  padding-left: 0!important;
  padding-right: 20px;
}
.formcss {
    padding: 15px 25px 10px 10px;
}
.kotbtn, .newordbtn {
    border: none;
    border-radius: .5rem;
    font-size: .875rem;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 32px;
    margin-top: 10px;
    padding: 0 17px;
    text-transform: capitalize;
}
.welcome-text {
    margin-bottom: 15px;
}
}

.vrttcrd .active {
  background: #ffcb44 !important;
}

.custom-cursor-pointer {
  cursor: pointer;
}
.kr-none{
  margin: none;
  padding: none;
}